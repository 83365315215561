<template>
    <section class="outContainer">
      <div class="search">
        <el-input class="each" v-model="searchInfo.param.account" placeholder="用户账号"></el-input>
        <el-input class="each" v-model="searchInfo.param.phone" placeholder="用户手机号"></el-input>
        <el-select class="each"  v-model="searchInfo.param.workStatus" placeholder="用户工作状态">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="handleAdd">新增</el-button>
      </div>
      <div class="tableContainer">
        <el-table
         :data="userInfoList"
         style="width: 100%;"
         height="100%"
         row-key="id"
        >
          <af-table-column
          prop="account"
          align='center'
          label="账号">
          </af-table-column>
          <af-table-column
          prop="phone"
          align='center'
          label="联系电话">
          </af-table-column>
          <af-table-column
          prop="workStatusStr"
          align='center'
          label="工作状态">
          </af-table-column>
          <af-table-column
          prop="roleName"
          align='center'
          label="角色">
          </af-table-column>
          <af-table-column
          prop="disabledStr"
          align='center'
          label="是否启用">
          </af-table-column>
          <af-table-column
          prop="createTime"
          align='center'
          label="创建时间">
          </af-table-column>
          <el-table-column label="操作" fixed="right" align='center' min-width="300">
            <template slot-scope="scope">
              <el-button size="mini" @click="resetPass(scope.row)">重置密码</el-button>
              <el-button size="mini" @click="handleLock(scope.row)">{{scope.row.disabled ? '启用':'禁用'}}</el-button>
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button size="mini" @click="handleClock(scope.row,1)" v-if="scope.row.workStatus == 2">打卡上班</el-button>
              <el-button size="mini" @click="handleClock(scope.row,2)" v-if="scope.row.workStatus == 1">打卡下班</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
        <!-- 新增 -->
        <el-drawer
         title="新增"
         :visible.sync="addDrawer"
         :direction="direction"
         size="50%"
         :before-close="handleClose">
          <add v-if="addDrawer" @close='handleClose' ref='newForm' :id="id" :row="row"></add>
        </el-drawer>
          <!-- 修改 -->
        <el-drawer
         title="修改"
         :visible.sync="editDrawer"
         :direction="direction"
         size="50%"
         :before-close="handleClose">
          <edit v-if="editDrawer" @close='handleClose' ref='newForm' :id="id" :row="row"></edit>
        </el-drawer>
      </div>
    </section>
</template>
  
  <script>
  import {getUserList,updateUserWork,forbiddenUser,resetUserPassword} from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  import add from './add.vue';
  import edit from './edit.vue';
  import pagination from '@/components/Pagination.vue';
  
  export default {
    data() {
      return {
        searchInfo:{
          pageNo:1,
          pageSize:10,
          total:0,
          param:{
            account: "",
            phone: "",
            workStatus: ""
          }
        },
        userInfoList: [],
        addDrawer:false,
        editDrawer:false,
        id:'',
        row:'',
        pageNo:1,
        pageSize:10,
        direction:'rtl',
        total:0,
        options:[
          {label:'上班中',value:1},
          {label:'休息中',value:2},
        ],
        userInfo:JSON.parse(localStorage.getItem('userInfo'))
      }
    },
  
    components: {
      add,
      edit,
      pagination,
    },
  
    computed: {},
  
    mounted() {
      this.init();
    },
    methods: {
      // 重置密码
      resetPass(row){
        this.$confirm('确认重置该账户的密码吗？').then(res=>{
          let data = {
            userId:row.id
          }
          resetUserPassword({param:data}).then(res=>{
            if(res.success){
              this.$confirm(`重置密码成功，重置后的密码为：${res.data}`)
            }
          })
        })
      },
      // 条件筛选事件
      handleSearch(type){
        if(type == 'clear'){
          this.searchInfo.param = {};
        }
        this.searchInfo.pageNo = 1;
        this.init();
      },
      // 页面初始化
      async init(){
        await getUserList(this.searchInfo).then(res=>{
          if(res.success){
            this.userInfoList = res.data.list;
            this.searchInfo.total = Number(res.data.total) ;
            this.userInfoList.forEach((element) => {
              let eachTime = timeChange(element.createTime);
              element.createTime = `${eachTime.year}-${eachTime.month}-${eachTime.day}  ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
            });
          }
        });
      },
      // 打开新增
      handleAdd(index,row){
        this.addDrawer=true;
      },
      // 打开修改
      handleEdit(index,row){
        this.editDrawer=true;
        this.id=index;
        this.row=row;
      },
      // 关闭
      handleClose(){
        this.row = null;
        this.addDrawer=false;
        this.editDrawer=false;
        this.init();
      },
      // 冻结、解冻
      handleLock(row){
        this.$confirm('确认启用/禁用该用户吗？').then(() => {
          let param = new URLSearchParams();
          param.append("userId", row.id);
          param.append("status", !row.disabled);
          forbiddenUser(param).then(res=>{
            if(res.success){
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.init();
            }
          })
        })
      },
      // 修改、新增、成功后
      handleDrawerSuccess(){
        this.addDrawer=false;
        this.editDrawer=false;
        this.init();
      },
      // 打卡
      handleClock(row,type){
        this.$confirm('确认进行打卡吗？').then(async (res)=>{
          let param = new URLSearchParams();
          param.append("userId", row.id);
          param.append("code", type);
          await updateUserWork(param).then(res=>{
          if(res.success){
              this.$message({
                message:'打卡成功',
                type:'success'
              })
            }else{
              this.$message({
                message:'打卡失败，请重试',
                type:'fail'
              })
            }
            this.init();
          })
        })
      },
      // 分页插件事件
      callFather(parm) {
        this.searchInfo.pageNo = parm.currentPage;
        this.init();
      },
    },
  };
  </script>
  <style scoped>
    .user{
      width: 100%;
      background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
    }
    .user button{
      position: absolute;
      right:20px;
      top:10px;
    }
  </style>
  