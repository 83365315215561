<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="账号" prop="account" >
        <el-input placeholder="请输入登录名" v-model="form.account"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone" >
        <el-input placeholder="请输入联系电话" v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <el-select v-model="form.roleId" placeholder="请选择角色" disabled>
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="add('form')" :plain='true'>立即提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import { updateUser,getRoleList } from '@/api/public.js';
import { phoneRight } from '@/api/publicFun.js';
export default {
  props:["row"],
  data() {
    return {
      form:{ // 表单数据
        roleId:"",
      },
      roleId:'',
      options:[], // 角色选项
      rules: { // 表单验证
        name: [
          { required: true, message: '用户名不得为空', trigger: 'blur', }
        ],
        account: [
          { required: true, message: '登录名不得为空', trigger: 'blur', }
        ],
        roleId: [
          { required: true, message: '角色不得为空', trigger: 'change', }
        ],
        password: [
          { required: true, message: '密码不得为空', trigger: 'blur', }
        ],
        phone: [
          { required: true, message: "联系电话不得为空", trigger: "blur" },
          { validator: phoneRight, trigger: 'blur' }
        ],
      },
      managerId:undefined,
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
    this.$nextTick(()=>{
      this.form = this.row;
    })
  },

  methods: {
    // 获取角色列表
    async init(){
      await getRoleList().then(res=>{
        if(res.success){
          this.options=res.data;
        }
      })
    },
    // 关闭弹框
    close(){
       _this.$emit('close');
    },
    //  添加
    add(form) {
       this.$refs["form"].validate(valid => {
          if (valid) {
            this.form.createTime = "";
            updateUser({param:this.form})
              .then((res) => {
                if (res.success) {
                  this.$message({
                    message: "提交成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.$emit('close');
                    this.init();
                  }, 1000);
                }else{
                  this.$message({
                    message: "提交失败，请重试",
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-select{
  width: 100%;
}
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
